import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import _ from "lodash";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const ContentListContainer = styled.div`
  ${tw`mt-5`}
`;
const GridContainer = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 p-4 gap-4`}
`;
const GridItem = styled.div`
  ${tw`px-4`}
`;
const MarkdownDiv = styled.div`
  ${tw`prose max-w-full`}
`;
const MarkdownDivSmall = styled.div`
  ${tw`
    prose-sm max-w-full 
    md:overflow-hidden md:text-ellipsis
    pr-2
  `}

  @media (min-width: 768px) {
    &.short {
      line-height: 1.7em;
      height: 8.3em;
      overflow: hidden;
    }
  }

  // scrollbar-width: thin;
  // scrollbar-color: #cfcfcf;
  // &::-webkit-scrollbar {
  //   width: 4px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: transparent;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #cfcfcf;
  //   border-radius: 4px;
  //   border: 1px solid #cfcfcf;
  // }
`;
const ImgContainer = styled.div`
  ${tw`mb-7 relative w-[250px] mx-auto`}
`;
const ClassYear = styled.div`
  ${tw`text-center mb-2.5 text-sm text-[#EC1F27] font-bold`}
`;
const Name = styled.div`
  ${tw`whitespace-nowrap absolute p-2 text-white bg-[#EC1F27] top-3/4 -left-1.5 text-sm font-bold`}
`;
const Title = styled.div`
  ${tw`text-center italic mb-5 text-base`}
`;
const ReadMore = styled.button`
  ${tw`hidden md:block text-sm text-[#EC1F27] hover:underline font-semibold`}
`;

const HallOfFamePage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs, contentList },
    },
  },
  location,
}) => {
  const breakpoints = useBreakpoint();
  const isMidScreen =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) > 1;
  // const mdContainers = useRef({})
  // const readMoreBtns = useRef({})

  // useEffect(() => {
  //   for (const idxKey in _.get(readMoreBtns, "current", {})) {
  //     const mdContainer = _.get(mdContainers, ["current", idxKey], {})

  //     if (mdContainer.scrollHeight > mdContainer.clientHeight) {
  //       const readMoreBtn = _.get(readMoreBtns, ["current", idxKey], {})
  //       _.set(readMoreBtn, ["style", "visibility"], "visible")
  //       _.set(readMoreBtn, "disabled", false)
  //     }
  //   }
  // }, [])

  // const readMore = idxKey => () => {
  //   const mdContainer = _.get(mdContainers, ["current", idxKey], {})
  //   const readMoreBtn = _.get(readMoreBtns, ["current", idxKey], {})

  //   if (mdContainer.classList.contains("short")) {
  //     mdContainer.classList.remove("short")
  //     readMoreBtn.innerText = "Read less"
  //   } else {
  //     mdContainer.classList.add("short")
  //     readMoreBtn.innerText = "Read more"
  //   }
  // }

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <ContentListContainer>
          {contentList.map((contentSection, outerIdx) => {
            const { type, markdown, panel, individuals } = contentSection;

            return (
              <React.Fragment key={outerIdx}>
                {type === "markdown" && (
                  <MarkdownDiv dangerouslySetInnerHTML={{ __html: markdown }} />
                )}
                {type === "list" && (
                  <GridContainer>
                    {individuals.map((profile, innerIdx) => {
                      const idxKey = `${outerIdx}|${innerIdx}`;
                      return (
                        <GridItem key={innerIdx}>
                          <ImgContainer>
                            <GatsbyImage
                              image={getImage(profile.image)}
                              alt="staff"
                              objectFit="contain"
                              style={{ height: "100%" }}
                              imgStyle={{ width: "100%", height: "100%" }}
                            />
                            <Name>{profile.name}</Name>
                          </ImgContainer>
                          <ClassYear>{profile.classYear}</ClassYear>
                          <Title>{profile.title}</Title>
                          <CollapsibleMD
                            markdown={profile.description}
                            isMidScreen={isMidScreen}
                          />
                          {/* <MarkdownDivSmall
                            className="short"
                            ref={el => (mdContainers.current[idxKey] = el)}
                            dangerouslySetInnerHTML={{
                              __html: profile.description,
                            }}
                          />
                          <ReadMore
                            onClick={readMore(idxKey)}
                            ref={el => (readMoreBtns.current[idxKey] = el)}
                          >
                            Read more
                          </ReadMore> */}
                        </GridItem>
                      );
                    })}
                  </GridContainer>
                )}
                {/* May not need accordion anymore */}
                {/* {type === "accordion" && (
                  <div>
                    {panel.map((p, idx) => {
                      const { header, individuals } = p
                      return (
                        <Collapse key={idx} header={header}>
                          <GridContainer>
                            {individuals.map((profile, index) => (
                              <GridItem key={index}>
                                <ImgContainer>
                                  <GatsbyImage
                                    image={getImage(profile.image)}
                                    alt="staff"
                                    objectFit="contain"
                                    style={{ width: "100%", height: "100%" }}
                                    imgStyle={{ width: "100%", height: "100%" }}
                                  />
                                </ImgContainer>
                                <Name>{profile.name}</Name>
                                <Title>{profile.title}</Title>
                                <MarkdownDivSmall
                                  dangerouslySetInnerHTML={{
                                    __html: profile.description,
                                  }}
                                />
                              </GridItem>
                            ))}
                          </GridContainer>
                        </Collapse>
                      )
                    })}
                  </div>
                )} */}
              </React.Fragment>
            );
          })}
        </ContentListContainer>
      </Container>
    </Layout>
  );
};

export default HallOfFamePage;

export const pageQuery = graphql`
  query HallOfFamePageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "hall-of-fame-page" } }) {
      frontmatter {
        seo {
          description
          title
        }
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        contentList {
          type
          markdown
          individuals {
            classYear
            description
            name
            title
            image {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.75, width: 250)
              }
            }
          }
          panel {
            header
            individuals {
              description
              name
              title
              image {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 0.75, width: 250)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MardkdownContainer = styled.div`
  ${({ isMidScreen, open }) =>
    isMidScreen && !open ? tw`max-h-[160px] overflow-hidden` : tw``}
`;

const CollapsibleMD = ({ markdown, isMidScreen }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <MardkdownContainer isMidScreen={isMidScreen} open={open}>
        <MarkdownDivSmall
          dangerouslySetInnerHTML={{
            __html: markdown,
          }}
        />
      </MardkdownContainer>
      <ReadMore onClick={handleClick}>Read {open ? "less" : "more"}</ReadMore>
    </>
  );
};

// const CollapseContainer = styled.div`
//   ${tw`border border-gray-200 rounded -mb-[1px]`}
// `
// const CollapseHeader = styled.div`
//   ${tw`flex justify-between py-4 px-6 bg-[#EC1F27]`}
// `
// const HeaderText = styled.div`
//   ${tw`pr-10 text-xl font-bold text-white`}
// `
// const IconContainer = styled.button`
//   ${tw`w-5 h-5 text-white`}
// `
// const Svg = styled.img`
//   ${tw`w-full h-full opacity-20`}
// `
// const CollapseContent = styled.div`
//   ${tw`transition-all overflow-hidden rounded bg-white`}
//   display: ${props => (props.open ? "block" : "none")};
// `

// const Collapse = ({ children, header }) => {
//   const [open, setOpen] = useState(true)

//   const handleClick = () => {
//     setOpen(!open)
//   }
//   return (
//     <CollapseContainer>
//       <CollapseHeader onClick={handleClick} open={open}>
//         <HeaderText>{header}</HeaderText>
//         <IconContainer open={open}>
//           {open ? <Icon name="expand_less" /> : <Icon name="expand_more" />}
//         </IconContainer>
//       </CollapseHeader>
//       <CollapseContent open={open}>{children}</CollapseContent>
//     </CollapseContainer>
//   )
// }
